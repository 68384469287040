import React from 'react'
import Index from '../../../../Assets/images/Index'
import InnerPageBanner from '../../../Common/InnerPageBanner/InnerPageBanner'
import GetInTouch from '../../../Common/GetInTouch/GetInTouch'
import CountContentBox from '../../../Common/CountContentBox/CountContentBox'
import Faq from '../../../Common/Faq/Faq'
import BlueBox from '../../../Common/BlueBox/BlueBox'
import TrustedByBrands from '../../../Common/TrustedByBrands/TrustedByBrands'
import SixBox from '../../../Common/SixBox/SixBox'
import TwoColList from '../../../Common/TwoColList/TwoColList'
import OptStore from '../../../Common/OptStore/OptStore'
import { Helmet } from 'react-helmet'
export default function Maintenance({ blueBoxRef, executeScroll, seo }) {
    const perksData = [
        { title: "Performance <br> Check ", content: "Regular performance checks keep your audience connected with your digital store. We schedule the performance checkups with automated software, ensuring optimal outcomes. " },
        { title: "Content Updation <br>&amp; Backup", content: "Our Shopify maintenance pack includes updating the content as per market requirements, and we take a backup of the content before any major updates to offer a smooth maintenance experience. " },
        { title: "Regular Mobile Optimization ", content: "Our Shopify maintenance experts help you run a fully responsive Shopify store under our maintenance services package. For this, we focus on loading speed, alignment issues, mobile speed, etc. " },
        { title: "Emergency <br> Support", content: "Unforeseen issues are part of running a Shopify store. However, when you partner with experienced Shopify maintenance experts, there is no need to worry. We address each issue with proper care. " },
        { title: "Top Shopify Upgrades ", content: "To grab the best features offered by Shopify, timely upgrades are essential. Though it can be a challenging task, with our expertise, you can experience hassle-free upgrades with maximum benefits. " },
        { title: "Smooth Tech <br> Support", content: "Our maintenance services deliver you 24*7 tech support so that you can contact us without any barriers. You can connect with us via various mediums like email, phone calls, or chat. We quickly respond to your issues. " },
    ]
    const whyhire = [
        { color: "#D4E9F3", title: "Reliable Services", desc: "Our proven experience in serving a wide range of industry verticals speaks about the reliability of our Shopify support and maintenance services. " },
        { color: "#FDEDAC", title: "Advanced <br> Tech", desc: "Our Shopify experts are equipped with the right advanced tools and technology to offer the best Shopify Maintenance support to meet your needs. " },
        { color: "#EAD3FF", title: "Timely <br> Reporting ", desc: "We regularly monitor your Shopify store and update you with monthly reports about the store's performance and areas that need further enhancements. " },
        { color: "#CFF6A1", title: "Unique Approach", desc: "We follow innovative techniques for upgrading the store and finding the scope of improvements. We leave no room for any mistakes as we put in all our efforts. " },
        { color: "#FFCFDE", title: "Affordable Services", desc: "We can offer you a variety of Shopify maintenance packages at different prices that are suitable for your budget and business requirements. " },
        { color: "#e2e7ff", title: "Quick <br>Response", desc: "Whenever you connect with us, we try to reach you as soon as possible to solve an issue and answer your queries so that you can rest assured. " },
    ]
    const faqData = [
        {
            id: 1, list: [
                { title: "What are Shopify's maintenance and support services? ", desc: "<p>It includes monitoring and upgrading the Shopify store regularly as per the business's needs to make sure your online business does not fall behind due to any bug or issue in the design and functionality. It entails data backup and other performance-related issues. </p>" },
                { title: "What is the cost of hiring a Shopify maintenance agency? ", desc: "<p>The cost for the Shopify maintenance services may vary based on your project size and complexity. To get an idea of the cost, you can connect with us by filling in the details in the form. </p>" },
                { title: "Are Shopify maintenance services necessary to earn more revenue? ", desc: "<p>Constant Shopify website maintenance is mandatory to keep your website running seamlessly and to offer a smooth user experience. And even if there is an issue, it gets addressed as soon as possible.</p>" },
                { title: "How can I request Shopify store maintenance services?  ", desc: "<p>You can submit your details in the form provided on our website. After your submission, a member of our team will instantly get back to you. And you can take the conversion further through emails. </p>" },
            ]
        },
        {
            id: 2, list: [
                {
                    title: "What are the benefits of Shopify maintenance services? ", desc: "<p>To ensure that your store is functioning properly with no downtime or fluctuations. With maintenance services, you can know what can go wrong in the coming time and take effective measures before anything actually goes wrong.</p>"
                }
            ]
        },
    ]
    const launchApp = [
        {
            title: "Why opt for Shopify Maintenance Services? ",
            paragraph: "You can spare more time to focus on other aspects of your online business to build a larger customer base and leave the task of Shopify store maintenance to us.",
            list: [
                { content: "Detailed Inspection of Store Data " },
                { content: "Full-scale Security Optimization " },
                { content: "Regular Performance Checks " },
                { content: "Increased Conversion Rate " },
                { content: "Mobily-friendly optimized Store " },
            ]
        },
    ]


    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://www.liquidwebdevelopers.com/services/shopify-store-maintenance" />
                <meta name="keywords" content={seo?.keywords} />
                <title>{seo?.title}</title>
                <meta name="description" content={seo?.description} />
            </Helmet>
            <InnerPageBanner
                bannerClass="pattens-blueBg servicestore_banner maintainanceBanner"
                heading={`<span class="highlight_text">Shopify  </span>  Maintenance Services  <span class="home_icon"> 🏪 </span>`}
                subtitle="Let us Manage Your Shopify Store &amp; <br/> Gain More Business "
                bannerImg={Index.maintanceBanner}
                arrow
                leftCol="col-md-6 col-lg-7"
                rightCol="col-md-6 col-lg-5"
            />
            <GetInTouch bgColor="var(--primaryBtn)" />
            <TwoColList
                largeImg={Index.intmaintanceStngBnrgrationImg}
                smallImg={Index.maintanceSetting}
                alt="shopify maintenance services"
                // commonClass="app-development-listing"
                heading={`Top-notch <span class="borderBtm" style="background-image: url(${Index.homePotential})"> Shopify </span> Website Maintenance Services`}>
                <div className="newStoreContent__text">
                    <p>At Liquidweb Developers, we have a team of experts who manage and maintain your Shopify stores with great care. We are the perfect choice for you to be at the top of the competition among online eCommerce businesses. </p>
                    <p>From monitoring the store loading speed to backing up the data and not missing the important upgrades; everything is in our hands. And you can stay completely relaxed! </p>
                    <p>Our high-quality Shopify support and maintenance services enable you to outperform your competitors by offering an uninterrupted browsing experience to your users. </p>
                    <p>Excited to be on the list of top Shopify stores? Then, what are you looking for? Hurry up, get in touch with us now!</p>
                </div>

            </TwoColList>

            <CountContentBox
                heading={`<h2 class="common_heading">Our Wide Range of Shopify <br/> Support  &amp; <span class="borderBtm" style="background-image: url(${Index.homeLiquidWebDeveloper})"> Maintenance </span> Services </h2>`}
                subHeading="Fully Secured and Upgraded Shopify Store "
                layoutType
                list={perksData}
            />


            <OptStore
                launchApp={launchApp}
                leftImg={Index.maintence3}
                sectionClass="greenBg"
                btn
                btnText="Get Free Estimation"
                btnWhite
                executeScroll={executeScroll}
                alt="shopify website maintenance"
            />
            <section className="services_main commonSection position-relative">
                <div className="container">
                    <div className="inner_container">
                        <div className="title_wrapper text-center">
                            <h2 className="common_heading"> Reasons to <span className="borderBtm" style={{ backgroundImage: `url(${Index.homeLiquidWebDeveloper})` }}> Choose Us </span> As  <br /> Your Shopify Maintenance Services </h2>
                        </div>
                        <SixBox listdata={whyhire} />
                    </div>
                </div>
            </section >
            <Faq faqData={faqData} />
            <TrustedByBrands commonClass title={`Valued by Global <span class="borderBtm" style="background-image: url(${Index.homeOffer})">Brands</span>`} />
            <BlueBox blueBoxRef={blueBoxRef} title="Get Advice from <br> Our Shopify Experts" subtitle="Leading Shopify Development Company" />
        </>
    )
}
